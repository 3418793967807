import { BaseStepController } from './base_step_controller.js'

export default class extends BaseStepController {
  static targets = [
    "form",
    "assetType",
    "chooseFile"
  ]

  static values = {
    gameId: String,
    taskGroupId: String,
    taskId: String,
    tasksPath: String,
    stepId: String,
    stepType: String,
    noNewStepButton: Boolean
  }

  connect() {
    $(".pop-over").popover({trigger: 'hover'});

    if (!this.noNewStepButtonValue) {
      this.hideAndShowAddNewStep(this.taskIdValue, true);
    }
    this.refreshStepOrder(this.taskIdValue);
    configurePermanent(this.taskIdValue);

    const $this = this
    // asset upload
    $('body').on('change', '.task_steps_asset_type select', function(event) {
      $this.handleAssetTypeSelection(event);
    });

    $('body').on('change', '.task_steps_step_file input', function(event) {
      $this.chooseFileChanged(event);
    });

    // setup bootstrap switches
    let stepId = "";
    if (this.stepIdValue != "new_step") {
      stepId = this.stepIdValue
    }

    if ($(`#bootstrap-switch-${stepId}.has-switch`).length === 0) {
      $(`#bootstrap-switch-${stepId}`).bootstrapSwitch();
    }

    if ($(`#bootstrap-switch-${stepId}-images.has-switch`).length === 0) {
      $(`#bootstrap-switch-${stepId}-images`).bootstrapSwitch();
    }

    this.matchTypeToggled($(`#bootstrap-switch-${stepId}`));

    window.toggleStepChallengeFields(this.stepTypeValue, $(this.element));
    window.hideAndShowTimeLimit($(this.element));
  }

  matchTypeToggled(stepSwitch) {
    window.ignoreChanges = true

    let activeForm = $(stepSwitch).closest('.step-form');
    if ($(stepSwitch).bootstrapSwitch('status')) {
      $(activeForm).find('.match_type').hide()
      $(activeForm).find('.answer_count').hide()
      $(activeForm).find('.image-answers').show()
      $(activeForm).find('.multi_choice_reward_type').show()
      $(activeForm).find('.answer_count').hide()
    } else {
      $(activeForm).find('.multi_choice_reward_type_option_boolean').click()
      $(activeForm).find('.match_type').show()
      $(activeForm).find('.answer_count').show()
      $(activeForm).find('.image-answers').hide()
      $(activeForm).find('.multi_choice_reward_type').hide()
      $(activeForm).find('.bootstrap-switch-answers').bootstrapSwitch('setState', false)
      $(activeForm).find('.answer_count').show()
    }
    toggleMultiChoicePoints($(activeForm).find('.multi_choice_reward_type_option:checked'))

    window.ignoreChanges = null
  }

  handleAssetTypeSelection(event) {
    let option = $(event.target).find(':selected');
    let activeForm = $(event.target).closest('.step-form');
    let upload_div = activeForm.find('.step_file_upload')[0];
    let duration_div = activeForm.find('.asset-duration')[0];
    resetUploadForForm(activeForm);
    if (option.val() == "None") {
      $(upload_div).hide();
      $(duration_div).hide();
      $(duration_div).find('input').val(0);
    } else {
      if (option.val() != "Video") {
        $(duration_div).show();
        $(duration_div).find('input').val(0);
      } else {
        $(duration_div).hide();
        $(duration_div).find('input').val(0);
      }
      $(upload_div).show();
    }
  }

  chooseFileChanged(event) {
    let activeForm = $(event.target).closest('.step-form')

    $(activeForm).find('.file-upload-error').hide();

    let extName;
    let maxFileBytes = 4194304;
    let maxVideoBytes = 20971520;
    let maxFileSize = bytesToSize(maxFileBytes);
    let maxVideoSize = bytesToSize(maxVideoBytes);
    let maxExceededMessage = "This file exceeds the maximum allowed file size" + ' (' + maxFileSize + ')';
    let maxVideoExceededMessage = "This file exceeds the maximum allowed file size" + ' (' + maxVideoSize + ')';
    let allowedExtension = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    let sizeExceeded = false;
    let videoSizeExceeded = false;
    let isVideo = false;
    let fileSize = event.target.files[0].size

    if (fileSize && maxFileBytes && fileSize > parseInt(maxFileBytes)) {
      sizeExceeded=true;
    }

    if (fileSize && maxVideoBytes && fileSize > parseInt(maxVideoBytes)) {
      videoSizeExceeded=true;
    }

    extName = $(event.target).val().split('.').pop();
    if ($.inArray(extName, allowedExtension) == -1) {
      isVideo=true;
    }

    // TODO: re-write this logic to be more readable
    if ((isVideo && videoSizeExceeded) || (!isVideo && sizeExceeded)) {
      if (isVideo){
        if (videoSizeExceeded) {
          $(event.target).val('');
          $(activeForm).find('.file-upload-error').text(maxVideoExceededMessage).show();
        }
      } else {
        if (sizeExceeded) {
          $(event.target).val('');
          $(activeForm).find('.file-upload-error').text(maxExceededMessage).show();
        }
      }
    } else {
      uploadStepAsset($(activeForm));
    }
  }

  revertChanges() {
    $(this.element).find("input:text, select, textarea").val('');
    $(this.element).find('#task_theme_list').trigger('change');
    $(this.element).find('#task_category_list').trigger('change');
  }

  chooseFileClicked(event) {
    $(event.target).val(null)
  }

  get taskGroupsListController() {
    return this.application.getControllerForElementAndIdentifier($("#task_group_list_data")[0], 'task-groups-list')
  }
}