import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    toolbar: String,
    plugins: String,
    height: Number,
    width: Number
  }

  connect() {
    tinyMCE.init({
      target: this.element,
      plugins: this.pluginsValue,
      toolbar: this.toolbarValue,
      menubar: false,
      statusbar: false,
      content_style: "body { font-size: 14px; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; color: rgb(85, 85, 85); }",
      height: this.heightValue || 300,
      width: this.widthValue || 277
    })
  }

  disconnect() {
    tinyMCE.get(this.element.id).remove()
  }
}