import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    stepId: String,
    signedUrlsPath: String,
    awsAccessKey: String,
    s3BucketName: String,
  }
  connect() {
    this.setupListeners()

    const $this = this;

    var handleRemove = function(event) {
      if (confirm('Remove upload?')) {
        isUnsaved();
        let activeForm = $(event.target).closest('.form-inputs')
        let arAssetType = $(event.target).parent().attr("id")
        $this.resetArUploadForForm(activeForm, arAssetType);
      }
    }
    $('body').on('click', '.ar-preview-remove', function(event) {
      handleRemove(event)
    })

    $('body').on('click', '.ar-wtc-remove', function(event) {
      handleRemove(event)
    })

    $('body').on('click', '.ar-dat-remove', function(event) {
      handleRemove(event)
    })

    $('body').on('click', '.ar-asset-remove', function(event) {
      handleRemove(event)
    })
  }

  disconnect() {
    $("body").off("click", ".ar-preview-remove")
    $("body").off("click", ".ar-wtc-remove")
    $("body").off("click", ".ar-dat-remove")
    $("body").off("click", ".ar-asset-remove")
  }

  setupListeners() {
    const $this = this;
    $(`#${this.stepIdValue}_ar_preview_file`).on('change', function(event) {
      let activeForm = $(event.target).closest('.step-form');
      $this.uploadArAsset(activeForm, `${$this.stepIdValue}_ar_preview_file`, `${$this.stepIdValue}_ar_preview_file_hidden`, 'ar-preview');
    })

    $(`#${this.stepIdValue}_ar_wtc_file`).on('change', function(event) {
      let activeForm = $(event.target).closest('.step-form');
      $this.uploadArAsset(activeForm, `${$this.stepIdValue}_ar_wtc_file`, `${$this.stepIdValue}_ar_wtc_file_hidden`, 'ar-wtc');
    })

    $(`#${this.stepIdValue}_ar_dat_file`).on('change', function(event) {
      let activeForm = $(event.target).closest('.step-form');
      $this.uploadArAsset(activeForm, `${$this.stepIdValue}_ar_dat_file`, `${$this.stepIdValue}_ar_dat_file_hidden`, 'ar-dat');
    })

    $(`#${this.stepIdValue}_ar_asset_file`).on('change', function(event) {
      let activeForm = $(event.target).closest('.step-form');

      if (!this.files[0]) {
        return;
      }
      let isVideo = activeForm.find('#step_ar_type').val() === "Video";
      let maxBytes = 10485760;
      let maxFileSize = bytesToSize(maxBytes);
      let maxExceededMessage = "Due to limitations with Wikitude, maximum allowed file size is " + maxFileSize;
      let fileSize = this.files[0].size;
      if (isVideo && fileSize > maxBytes) {
        alert(maxExceededMessage);
        return;
      }

      $this.uploadArAsset(activeForm, `${$this.stepIdValue}_ar_asset_file`, `${$this.stepIdValue}_ar_asset_file_hidden`, 'ar-asset');
    })
  }

  resetArUploadForForm(activeForm, arAssetType) {
    // replace upload field with a clone of self (without values)
    let finput = activeForm.find(`.${arAssetType}-file-input`)[0];
    $(finput).replaceWith($(finput).clone());
    activeForm.find('.asset-identifier').val(null);
    activeForm.find(`.${arAssetType}-file-ui`).hide();
    activeForm.find(`#${arAssetType}-filename`).text(null);
    activeForm.find(`.${arAssetType}-filepicker`).show();
    activeForm.find('.asset-type-picker').show();
    activeForm.find(`#${this.stepIdValue}_${arAssetType.replace("-", "_")}_file_hidden`).val('');
    // reset listeners since we replaced the input el
    this.setupListeners()
  }

  uploadArAsset(activeForm, upload_input, key_input, assetType) {
    $(activeForm).find('.file-upload-error').hide();
    if ($(activeForm).find('#' + upload_input).val()){
      uploadAsset(
        this.signedUrlsPathValue,
        this.awsAccessKeyValue,
        this.s3BucketNameValue,
        upload_input,
        $(activeForm).find('.progress'),
        $(activeForm).find('.bar'),
        function(key) {
          if (key != null) {
            $(activeForm).find('#' + upload_input).parents(`.${assetType}-filepicker`).hide();
            $(activeForm).find('#' + key_input).val(key);
            $(activeForm).find('#' + upload_input).parents('.control-group').find(`.${assetType}-file-ui`).show();
            $(activeForm).find('#' + upload_input).parents('.control-group').find(`.${assetType}-file-ui`).find('.filename').html($(activeForm).find('#' + upload_input).val().replace(/C:\\fakepath\\/i, ''));
          } else {
            $(activeForm).find('.file-upload-error').show();
          }
        }
      )
    }
  }
}